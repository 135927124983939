import DrDivider from '@components/DrDivider';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const Menu = dynamic(() => import('@mui/material/Menu'));

// Assets
import MainAvatar from '@components/profile/MainAvatar';
import { DreamerlyUser } from '@src/providers/AuthProvider';
import captureException from '@src/services/loggerService';
import { useSearchSuggestionResultQuery } from '@src/services/searchHooks';
import IconSearchWhiteSVG from '/public/ic-search-white.svg';
import IconSearchSVG from '/public/ic-search.svg';

type SearchFreeSoloInputProps = {
  inputOnAutoFocus?: boolean;
  authUser?: DreamerlyUser | null | undefined;
};

/**
 * A free solo autocomplete input field for search on app bar.
 *
 * References: Stripe's Create Payment Link UI
 */
function SearchFreeSoloInput(props: SearchFreeSoloInputProps) {
  const { inputOnAutoFocus, authUser } = props;
  const [query, setQuery] = useState<string>('');
  const [userSearchResult, setUserSearchResult] = useState<any>([]);
  const [suggestedQuerySearchResult, setSuggestedQuerySearchResult] = useState<any>([]);
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // Open menu when the anchorEl is present
  const isMenuOpen = Boolean(anchorEl);
  const router = useRouter();

  // To close the menu, set anchorEl to null
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  /**
   * Handler when user types
   */
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Update the value to the user input
    const { value } = e.target;
    setQuery(value);
  };

  /**
   * Handler when user presses on input
   */
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // If user presses Enter and if value is not empty, navigate to the search
    // page
    if (e.key === 'Enter' && query.trim()) {
      router.push(`/search/result?searchQuery=${query.trim()}`);
    }
  };

  const handleOnFocus = async (value: string) => {
    setAnchorEl(anchorRef.current);
    setQuery(value);
    // Mixpanel
    try {
      const { MixpanelTracking } = await import('@src/utils/mixpanelService');

      MixpanelTracking.getInstance().trackStartSearch({
        fromScreen: router.pathname || '',
        platform: 'DESKTOP_WEB',
        isLoggedIn: authUser ? true : false,
      });
    } catch (error) {
      captureException(error);
    }
  };

  const handleOnUserClick = async (userId: string, userType: string, isSelling: boolean) => {
    // Mixpanel
    try {
      const { MixpanelTracking } = await import('@src/utils/mixpanelService');

      MixpanelTracking.getInstance().trackFromSearchToUser({
        searchKeyword: query || '',
        platform: 'UNKNOWN',
        isLoggedIn: authUser ? true : false,
        currentTab: 'none',
        currentPage: 1,
        filterUserType: 'none',
        filterCommissionStatus: 'none',
        filterStarRating: 'none',
        filterCategory: 'none',
        filterPriceRange: 'none',
        userId,
        userType,
        isSelling,
        isFromSuggestion: true,
        divId: 'none',
      });
    } catch (error) {
      captureException(error);
    }
  };

  const handleSearchQueryClick = async (suggestedQuery: string) => {
    console.log('handleSearchQueryClick: ', suggestedQuery);
    // Mixpanel
    try {
      const { MixpanelTracking } = await import('@src/utils/mixpanelService');

      MixpanelTracking.getInstance().trackUseSearchSuggestion({
        searchKeywork: query,
        searchSuggestionKeyword: suggestedQuery,
        platform: 'DESKTOP_WEB',
        isLoggedIn: authUser ? true : false,
      });
    } catch (error) {
      captureException(error);
    }
    if (!router) return;
    const searchUrl = '/search/result?searchQuery=' + suggestedQuery;
    router.push(searchUrl);
  };

  const { data: searchSuggestionResultData } = useSearchSuggestionResultQuery(query);

  // Hooks
  useEffect(() => {
    if (!searchSuggestionResultData) return;
    const searchResult = searchSuggestionResultData.results || [];
    console.log('multisearchResult: ', searchResult);
    // Split multi search result into the correct search type
    const userResult = searchResult.filter((itemCollection: any) => {
      return itemCollection.request_params.collection_name === 'dreamerly_users';
    }) as any[];
    if (userResult.length === 1) {
      setUserSearchResult(userResult[0].hits);
    }
    const queryResult = searchResult.filter((itemCollection: any) => {
      return itemCollection.request_params.collection_name === 'suggested_queries';
    }) as any[];
    if (queryResult.length === 1) {
      setSuggestedQuerySearchResult(queryResult[0].hits);
    }
  }, [searchSuggestionResultData]);

  return (
    <div className="flex w-full items-center">
      <div
        className="flex items-center rounded-full
            w-full h-8 px-3 my-2 lg:mx-4 border border-greyscale-g3 bg-gray-50"
        ref={anchorRef}
      >
        <div className="flex items-center shrink-0">
          <IconSearchSVG />
        </div>
        <input
          name="search_query"
          type="text"
          role="combobox"
          autoComplete="off" // Add these params to avoid password manager autofill
          autoCapitalize="off"
          autoCorrect="off"
          aria-autocomplete="none"
          aria-expanded={anchorEl ? 'true' : 'false'}
          spellCheck="false"
          className="text-s-tall placeholder:text-content-t300 outline-none px-2 w-full bg-gray-50"
          placeholder="Tìm kiếm người dùng, loại commission, tranh..."
          value={query}
          onChange={onInputChange}
          onKeyDown={onKeyDown}
          onFocus={() => handleOnFocus(query)}
          autoFocus={inputOnAutoFocus}
        />
      </div>
      <Menu
        anchorEl={anchorEl}
        id="product-name-free-solo-input-menu"
        open={isMenuOpen}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        disableAutoFocus // Need this to keep the focus in the input field when menu opens
        PaperProps={{
          elevation: 0,
          sx: {
            filter: 'drop-shadow(0px 0px 6px rgba(0,0,0,0.20))',
            mt: 1,
            borderRadius: '8px',
            maxHeight: '400px',
            width: anchorEl && anchorEl.offsetWidth,
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        {!!query && (
          <div className="px-2">
            <Link href={`/search/result?searchQuery=${query.trim()}`}>
              <a
                className="flex px-2 py-1.5 items-center w-full rounded-lg text-primary-p4
            hover:bg-greyscale-g1 font-medium"
              >
                <div className="h-9 w-9 shrink-0 rounded-full bg-primary-p4 flex items-center justify-center">
                  <IconSearchWhiteSVG />
                </div>
                <span className="ml-3 text-s-tall whitespace-nowrap text-ellipsis overflow-hidden">
                  Tìm kiếm&nbsp;
                  <span className="font-semibold ">{query}</span>
                </span>
              </a>
            </Link>
          </div>
        )}
        <div className="grid grid-cols-1 px-2">
          {suggestedQuerySearchResult.map((data: any) => {
            const query = data.document;
            return (
              <div
                className="flex px-2 py-1.5 items-center hover:bg-greyscale-g1 hover:rounded-lg"
                key={uuidv4()}
                role="button"
                onClick={async () => await handleSearchQueryClick(query.q)}
              >
                <div className="h-9 w-9 shrink-0 rounded-full bg-greyscale-g2 flex items-center justify-center">
                  <IconSearchSVG />
                </div>
                <span className="ml-3 text-s font-semibold">{query.q}</span>
              </div>
            );
          })}
          {suggestedQuerySearchResult.length > 0 && (
            <div className="my-2">
              <DrDivider />
            </div>
          )}
          {/* User search result */}
          {userSearchResult.length > 0 && (
            <div className="flex flex-col">
              {userSearchResult.map((data: any) => {
                const user = data.document;
                return (
                  <Link key={user.id} href={`/users/${user.username}`} passHref>
                    <a
                      className="rounded-lg px-2 py-1.5 flex flex-row items-center hover:bg-greyscale-g1"
                      onClick={async () =>
                        await handleOnUserClick(
                          user.id,
                          user.is_artist === 1 ? 'seller' : 'buyer',
                          user.is_selling === 1 ? true : false,
                        )
                      }
                    >
                      <MainAvatar
                        sizeType="xs-medium"
                        imageSrc={user.profile_picture_url || ''}
                        linkUrl={`/users/${user.username}`}
                        displayName={user.display_name}
                        canOpenProfile={false}
                        isOpenNewTab={false}
                      />
                      <div className="ml-3 flex flex-col">
                        <div className="text-s pb-1 font-semibold">{user.display_name}</div>
                        <div className="text-xs text-content-t200">
                          {user.is_artist === 0 ? 'Khách hàng' : 'Hoạ sĩ'}
                        </div>
                      </div>
                    </a>
                  </Link>
                );
              })}
            </div>
          )}
        </div>
      </Menu>
    </div>
  );
}

export default SearchFreeSoloInput;
