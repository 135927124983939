import Box from '@mui/material/Box';
import useWindowDimensions from '@src/hooks/useWindowDimensions';
import { MD_SCREEN_IN_PX } from '@src/utils/constants';
import dynamic from 'next/dynamic';
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';

const Modal = dynamic(() => import('@mui/material/Modal'));

type ModalProviderProps = {
  children: ReactNode;
};

export type ModalContextType = {
  setModalContent: (content: ReactNode) => void;
  closeModal: () => void;
};

const defaultModalContextValue = {
  setModalContent: (content: ReactNode) => null,
  closeModal: () => null,
};

const ModalContext = createContext<ModalContextType>(defaultModalContextValue);

export default function ModalProvider({ children }: ModalProviderProps) {
  const [modalContent, setModalContent] = useState<ReactNode>(null);
  const [modalWidth, setModalWidth] = useState('100%');
  // tn-example
  const { width } = useWindowDimensions();
  // Whether the model is open or not depends on whether there is modal content
  const isModalOpen = !!modalContent;

  useEffect(() => {
    if ((width || 0) > MD_SCREEN_IN_PX) {
      setModalWidth('568px');
    } else {
      setModalWidth('100%');
    }
  }, [width]);

  // useCallback to cache the function closeModal()
  const closeModal = useCallback(() => {
    setModalContent(null);
  }, [setModalContent]);

  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: modalWidth,
  };

  return (
    <ModalContext.Provider value={{ setModalContent, closeModal }}>
      <Modal open={isModalOpen} onClose={closeModal}>
        <Box
          sx={{
            ...style,
          }}
        >
          <div>{modalContent}</div>
        </Box>
      </Modal>
      {children}
    </ModalContext.Provider>
  );
}

export const useModal = (): ModalContextType => {
  const context = useContext(ModalContext);
  if (!context) {
    throw Error('Use useModal in ModalProvider');
  }
  return context;
};
