import { useQuery } from '@tanstack/react-query';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: process.env.NEXT_PUBLIC_TYPESENSE_READ_ONLY_API_KEY || '',
    nodes: [
      {
        host: process.env.NEXT_PUBLIC_TYPESENSE_HOST_ADDRESS || '',
        port: 443,
        path: '',
        protocol: 'https',
      },
    ],
    cacheSearchResultsForSeconds: 2 * 60,
  },
  // Common search parameters that need to be *added* to every search request go here:
  additionalSearchParameters: {
    numTypos: '3',
  },
  // Search parameters that need to be *overridden* on a per-collection-basis go here:
  collectionSpecificSearchParameters: {
    dreamerly_users: {
      query_by: 'username, display_name, term_of_service, categories',
    },
  },
});
const searchClient = typesenseInstantsearchAdapter.typesenseClient;

/**
 * React Query hook to search for all results.
 */
export const useSearchAllResultQuery = (query: string | null | undefined) => {
  return useQuery({
    // Query keys: name of service, name of method, arguments
    queryKey: ['searchClient', 'searchAllResult', query],
    queryFn: async () => {
      if (!query) return undefined;
      const searchRequests = {
        searches: [
          {
            collection: 'dreamerly_users',
            q: !query ? '*' : query,
            query_by: 'username, display_name, term_of_service, categories',
            per_page: 3,
            sort_by: 'is_artist:desc',
          },
          {
            collection: 'dreamerly_products',
            q: query || '*',
            query_by:
              'display_name, categories, price_names, description, artist_username, artist_display_name',
            per_page: 3,
          },
          {
            collection: 'dreamerly_artworks',
            q: !query ? '*' : query,
            query_by: 'tags, artist_categories, artist_username, artist_display_name',
            per_page: 6,
          },
        ],
      };
      return await searchClient.multiSearch.perform(searchRequests, {});
    },
    enabled: !!query,
  });
};

/**
 * React Query hook to search for artwork results.
 */
export const useSearchArtworkResultQuery = (
  query: string | null | undefined,
  currentPage: number,
) => {
  return useQuery({
    // Query keys: name of service, name of method, arguments
    queryKey: ['searchClient', 'searchArtworkResult', query, currentPage],
    queryFn: async () => {
      if (!query) return undefined;
      const searchParameters = {
        q: !query ? '*' : query,
        query_by: 'tags, artist_categories, artist_username, artist_display_name',
        per_page: 20,
        page: currentPage,
      };
      return await searchClient
        .collections('dreamerly_artworks')
        .documents()
        .search(searchParameters, {});
    },
    enabled: !!query,
  });
};

/**
 * React Query hook to search for product results.
 */
export const useSearchProductResultQuery = (
  query: string | null | undefined,
  currentPage: number,
  filterByQuery: string,
) => {
  return useQuery({
    // Query keys: name of service, name of method, arguments
    queryKey: ['searchClient', 'searchProductResult', query, currentPage, filterByQuery],
    queryFn: async () => {
      if (!query) return undefined;
      const searchParameters = {
        q: !query ? '*' : query,
        query_by:
          'display_name, categories, price_names, description, artist_username, artist_display_name',
        filter_by: filterByQuery,
        per_page: 10,
        page: currentPage,
      };
      return await searchClient
        .collections('dreamerly_products')
        .documents()
        .search(searchParameters, {});
    },
    enabled: !!query,
  });
};

/**
 * React Query hook to search for suggestion results.
 */
export const useSearchSuggestionResultQuery = (query: string | null | undefined) => {
  return useQuery({
    // Query keys: name of service, name of method, arguments
    queryKey: ['searchClient', 'searchSuggestionResult', query],
    queryFn: async () => {
      if (query === null || query === undefined) return undefined;
      const searchRequests = {
        searches: [
          {
            collection: 'dreamerly_users',
            q: !query ? '*' : query,
            query_by: 'username, display_name, term_of_service, categories',
            per_page: 7, // only fetch 5 hits per collection, full list show on other tab
            sort_by: 'is_artist:desc',
          },
          {
            collection: 'suggested_queries',
            q: query || '*',
            query_by: 'q',
            per_page: 5, // only fetch 7 hits per collection, full list show on other tab
          },
        ],
      };
      return await searchClient.multiSearch.perform(searchRequests, {});
    },
  });
};

/**
 * React Query hook to search for user results.
 */
export const useSearchUserResultQuery = (
  query: string | null | undefined,
  currentPage: number,
  filterByQuery: string,
) => {
  return useQuery({
    // Query keys: name of service, name of method, arguments
    queryKey: ['searchClient', 'searchUserResult', query, currentPage, filterByQuery],
    queryFn: async () => {
      if (!query) return undefined;
      const searchParameters = {
        q: !query ? '*' : query,
        query_by: 'username, display_name, term_of_service, categories',
        filter_by: filterByQuery,
        per_page: 10,
        page: currentPage,
        sort_by: 'is_artist:desc',
      };
      return await searchClient
        .collections('dreamerly_users')
        .documents()
        .search(searchParameters, {});
    },
    enabled: !!query,
  });
};
