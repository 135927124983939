import AppBar from '@mui/material/AppBar';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';

const ProfileMenu = dynamic(() => import('@components/header/ProfileMenu'), { ssr: true });
const NotificationButton = dynamic(() => import('@components/header/NotificationButton'), {
  ssr: true,
});
const InboxButton = dynamic(() => import('@components/header/InboxButton'), { ssr: true });

// Assets
import { useAuthState } from '@src/providers/AuthProvider';
import { useModal } from '@src/providers/ModalProvider';
import { useTranslation } from 'react-i18next';
import HeaderSearchInput from './HeaderSearchInput';
import SearchFreeSoloInput from './SearchFreeSoloInput';
import LogoHorizontalPrimaryTextSVG from '/public/ic-logo-horizontal-primary-text.svg';

const DefaultButtonClassName =
  'flex flex-col mx-2 xl:mx-3 justify-center h-full ' +
  ' hover:pt-0.5 hover:border-b-2 hover:border-greyscale-g3 w-fit';
const SelectedButtonClassName =
  'flex flex-col mx-2 xl:mx-3 justify-center pt-0.5 h-full border-b-2 border-primary-p4 w-fit';
const DefaultTextClassName =
  'shrink-0 text-content-t200 text-s-tall font-semibold hover:text-content-t100 w-fit';
const SelectedTextClassName = 'shrink-0 text-primary-p4 text-s-tall font-semibold w-fit';

export function Logo() {
  return (
    <Link href="/" passHref prefetch={false}>
      <a id="link-header-logo" href="replace" className="my-1 flex shrink-0 grow-0 items-center">
        <div className="items-center justify-center hidden md:flex">
          <LogoHorizontalPrimaryTextSVG viewBox="0 0 180 40" className="h-8 w-36" />
        </div>
        <div className="flex md:hidden items-center justify-center">
          <LogoHorizontalPrimaryTextSVG viewBox="0 0 180 40" className="h-8 w-36" />
        </div>
      </a>
    </Link>
  );
}

export function NavigationItem({
  id,
  currentPath,
  href,
  text,
}: {
  id: string;
  currentPath: string;
  href: string;
  text: string;
}) {
  return (
    <Link href={href} passHref prefetch={false}>
      <a
        id={id}
        className={currentPath === href ? SelectedButtonClassName : DefaultButtonClassName}
      >
        <span className={currentPath === href ? SelectedTextClassName : DefaultTextClassName}>
          {text}
        </span>
      </a>
    </Link>
  );
}

function Header({
  position = 'sticky',
  hasShadow = true,
  isWide = false,
}: {
  position?: 'fixed' | 'relative' | 'static' | 'absolute' | 'sticky' | undefined;
  hasShadow?: boolean;
  isWide?: boolean;
}) {
  /* Hooks */
  const { user: authUser } = useAuthState();

  const router = useRouter();
  const currentPath = router.pathname;
  const { t } = useTranslation('common');
  const { setModalContent } = useModal();

  return (
    <AppBar position={position} color="inherit" elevation={0}>
      {authUser?.username &&
        authUser?.isArtist &&
        authUser?.mst_stage !== 'CCCD_AND_MST_PROVIDED' && (
          <div className="bg-[#E34140] py-4 px-4">
            <div className="container mx-auto flex items-center justify-center">
              <p className="text-white text-lg mr-4 flex-1 text-center">
                Theo quy định của các cơ quan chức năng, hoạ sĩ Dreamerly cần cung cấp thông tin
                CCCD và mã số thuế để giao dịch trên nền tảng.
              </p>
              <Link href={`/users/${authUser?.username}`} passHref prefetch={false}>
                <button className="bg-white font-semibold py-2 px-6 rounded-lg min-w-[120px]">
                  <a>Bắt đầu</a>
                </button>
              </Link>
            </div>
          </div>
        )}
      {/* TODO (long): Fix responsiveness for tablet */}
      <div
        className={`flex flex-col bg-cover ${isWide ? 'px-4 md:px-6' : 'px-4 md:px-6 lg:px-12'} ${
          hasShadow ? 'shadow' : 'border-b border-greyscale-g3'
        } items-center justify-center`}
      >
        <div className="flex flex-row items-center w-full justify-between">
          <div className="flex flex-row flex-grow">
            <Logo />

            <div className="hidden md:flex flex-row ml-1 xl:ml-5 items-center">
              <NavigationItem
                id="link-header-home"
                currentPath={currentPath}
                href="/"
                text={t('Khám phá')}
              />
              <NavigationItem
                id="link-header-artworks"
                currentPath={currentPath}
                href="/artworks"
                text={t('Phòng tranh')}
              />
              <NavigationItem
                id="link-header-job"
                currentPath={currentPath}
                href="/jobs"
                text={t('Chợ commission')}
              />
            </div>

            {/* Fixed search input: Show for desktop only. */}
            <div className="hidden md:flex md:flex-grow">
              <SearchFreeSoloInput authUser={authUser} />
            </div>

            {/* Search input for mobile. */}
            <div className="flex flex-row items-centers w-full px-2 md:hidden">
              <HeaderSearchInput authUser={authUser} />
            </div>
          </div>

          <div className="flex flex-row items-center md:space-x-1.5">
            {!(currentPath === '/inbox') && (
              <div className="hidden md:block">
                <InboxButton />
              </div>
            )}

            <NotificationButton />
            {/* Hide language selector for now */}
            {/* {!authUser && (
              <button
                className="flex flex-row items-center p-1.5"
                onClick={() => {
                  setModalContent(<LanguageSelector />);
                }}
              >
                <Image
                  alt="Shopping"
                  src="/ic-language-selector.svg"
                  width={24}
                  height={24}
                  priority
                />
              </button>
            )} */}

            <div className="hidden md:block">
              <ProfileMenu />
            </div>
          </div>
        </div>
      </div>
    </AppBar>
  );
}

export default Header;
