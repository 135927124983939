import { DreamerlyUser } from '@src/providers/AuthProvider';
import captureException from '@src/services/loggerService';
import { useRouter } from 'next/router';
import IconSearchSVG from '/public/ic-search.svg';

function HeaderSearchInput({ authUser }: { authUser?: DreamerlyUser | null | undefined }) {
  /* Hooks */
  const router = useRouter();

  /* States */

  /* Functions */
  const onFocus = async () => {
    // Mixpanel
    try {
      const { MixpanelTracking } = await import('@src/utils/mixpanelService');

      MixpanelTracking.getInstance().trackStartSearch({
        fromScreen: router.pathname || '',
        platform: 'MOBILE_WEB',
        isLoggedIn: authUser ? true : false,
      });
    } catch (error) {
      captureException(error);
    }
    router.push('/search/query');
  };

  /* Views */
  return (
    <div className="w-full lg:flex">
      <div className="flex flex-row flex-grow">
        <div className="flex flex-row items-centers w-full">
          <div className="flex w-full items-center">
            <div
              className="flex items-center rounded-full
            w-full shrink-0 h-8 px-3 my-2 lg:mx-4 border border-greyscale-g3 bg-gray-50"
              onClick={onFocus}
            >
              <div className="flex items-center shrink-0">
                <IconSearchSVG viewBox="0 0 21 20" className="w-5 h-5" />
              </div>
              <input
                name="search_query"
                type="text"
                role="combobox"
                autoComplete="off" // Add these params to avoid password manager autofill
                autoCapitalize="off"
                autoCorrect="off"
                aria-autocomplete="none"
                aria-expanded={'false'}
                spellCheck="false"
                className="text-s-tall placeholder:text-content-t300 outline-none px-2 w-full bg-gray-50"
                placeholder="Tìm kiếm..."
                value=""
                readOnly
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderSearchInput;
